.buy-now-button {
    padding: 10px 20px;
    background-color: #8f5deb;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background-color: #8e52ff;
  }
.buy-project-information{
  background-color: #e9defc;
  border-radius: .5rem;
}  

.buy-project-information p{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
}  

.buy-project-subtitle{
  color: #7a3deb;
}