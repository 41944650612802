/* Codebook.css */

.codeblock {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: .5rem;
  margin-bottom: 2rem;
}

.codeblock h5 {
  text-decoration: none;
}

@media (max-width: 768px) {
  .codeblock {
    display: flex;
    flex-direction: column;
  }

  .codeblock .col-md-4 {
    flex: 1 0 auto;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .codeblock .col-md-8 {
    flex: 1 0 auto;
    max-width: 100%;
  }
}

.sidebar {
  margin-top: 2rem;
}

.ad-placeholder {
  margin-top: 2rem;
  background-color: #f0f0f0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .codeblock {
    flex-direction: column;
  }

  .ad-placeholder {
    height: 150px;
  }
}
