/* Add this CSS to your Blog component or import it from an external stylesheet */


.container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .col-md-3,
  .col-md-9 {
    padding: 0 15px;
  }
  
  .ad-placeholder {
    background-color: #000000bb;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .blogtitle {
    font-family:Arial, Helvetica, sans-serif;
    /* font-size: large; */
    font-size: 2rem;
    margin-bottom: 10px;
    color: rgb(224, 16, 16);
    font-weight: bold;
  }
  
  .blog-date {
    
    font-size: large;
    color: #888888;
  }

  .projectimage{
    width: 100%;
    /* max-height: 300px; */
    object-fit: cover;
    border-radius: .6rem;
    

  }
  
  .blogimage {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: rgba(76, 77, 76, 0.226) 0px 0px 5px 0px, rgba(51, 51, 51, 0.63) 0px 0px 1px 0px;
  }
  
  .blogsubtitle {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .blog-content {
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    margin-top: 20px;
    line-height: 1.6;
  }
  
  .sharebutton {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #333;
  }
  
  .related-posts {
    margin-top: 10px;
  }
  
  .tag-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .badge {
    text-decoration: none;
    background-color: #ffffff;

    padding: .6rem 1rem;
    margin-right: .5rem;
    margin-bottom: 5px;
    /* border: .01rem solid #333; */
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .badge a{
    color: #000000;
    text-decoration: none;
  }
  
  .my-5 {
    margin-bottom: 5rem;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .col-md-3,
    .col-md-9 {
      width: 100%;
      padding: 0;
    }
  }
  /* Add this CSS to your existing stylesheet */

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 1rem;
}

.blogimage {
  cursor: pointer;
}

.blogimage:hover + .popup {
  display: flex;
}

/* .pagination-container{
  background-color: #0d6efd;
  margin: 1rem;
} */
.pagination .page-button{
  background-color: #6213e2c2;
  color: white;
  border: none;
  margin-top: .1rem;
  margin-right: .2rem;
}

.suggestions:hover{
  background-color: #2f05ff11;
}
.suggestions a{
  color: #000000;
  text-decoration: none;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: small;
  font-weight: 500;
}
.suggestions a:hover{
  color: #3c18f3;
  text-decoration: underline;
  
}









/* BlogPage.css */
.custom-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: rgba(60, 64, 67, 0.4) 0px 4px 6px -1px, rgba(60, 64, 67, 0.3) 0px 2px 4px -1px;
}

.card-img-container {
  height: 15rem;
  position: relative;
}

.card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}

.card-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.card-text {
  color: #777;
}

.page-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.page-button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
