.search-container {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
}

form {
  display: flex;
  width: 100%;
}

input {
  flex: 1;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  outline: none; /* Remove default outline */
}

input:focus {
  border-color: #7130ebcc;
  outline: 2px solid #7130ebcc; /* Add custom outline on focus */
}

#searchbtn {
  width: 5rem;
  background-color: #7130ebcc;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.search-icon {
  width: 20px;
  height: 20px;
}
