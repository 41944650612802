/* ServicePopup.css */

.service-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.service-popup {
  background-color: #9926f0;
  color: #fff;
  padding: 20px;
  max-width: 70rem;
  border-radius: 1.5rem;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0.8);
}

.service-popup-overlay.open,
.service-popup.open {
  opacity: 1;
}

.service-popup.open {
  transform: scale(1);
}

.service-popup-content {
  text-align: center;
}

.popup-contact-button,
.popup-close-button {
  background-color: #f5e9ff;
  color: #9926f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
}

.popup-close-button {
  background-color: #292626;
  color: #fff;
}

.service-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* Hide list bullets */
.service-popup-content ul {
  list-style-type: none;
  padding: 0;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .service-popup {
    max-width: 90%;
  }
}
