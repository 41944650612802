/* Footer Styles */
.footer {
  background-color: #333;
}

.footer-copyright a {
  color: #fff;
  text-decoration: none;
}

.footer-copyright a:hover {
  text-decoration: underline;
}

.footer-social {

  padding: 10px 0;
}

.footer-social a {
  color: #fff;
  margin: 0 10px;
}

.footer-social a:hover {
  color: #b8adff;
}
