
  /* PowerPointViewer.css */
.powerpoint-container {
  width: 100%;
  height: fit-content;
}

.powerpoint-container iframe {
  width: 100%;
  
}
