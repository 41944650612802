#rotate {
  position: fixed;
  color: #ffffff;
  background-color: #9d31f0;
  border: 1px solid white;
  border-radius: .4rem;
  top: 50%;
  left: -4.15rem;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg); 
  -webkit-transform: rotate(90deg); 
  -o-transform: rotate(90deg); 
  z-index: 9999; 
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
