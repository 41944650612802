#payment-container {
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .success-message {
    color: green;
    font-weight: bold;
  }
  
  .input-field,
  #pay-now-btn {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  #pay-now-btn {
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  #pay-now-btn:hover {
    background-color: #0056b3;
  }
  