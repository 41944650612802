/* Add this CSS to your Blog component or import it from an external stylesheet */


.container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .col-md-3,
  .col-md-9 {
    padding: 0 15px;
  }
  
  .ad-placeholder {
    background-color: #edfff0;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .blogtitle {
    font-family:Arial, Helvetica, sans-serif;
    /* font-size: large; */
    font-size: 2rem;
    margin-bottom: 10px;
    color: rgb(224, 16, 16);
    font-weight: bold;
  }
  
  .blog-date {
    
    font-size: large;
    color: #888888;
  }

  .projectimage{
    width: 100%;
    /* max-height: 300px; */
    object-fit: cover;
    border-radius: .6rem;
    

  }
  
  .blogimage {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: rgba(76, 77, 76, 0.226) 0px 0px 5px 0px, rgba(51, 51, 51, 0.63) 0px 0px 1px 0px;
  }
  
  .blogsubtitle {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .blog-content {
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    margin-top: 20px;
    line-height: 1.6;
  }
  
  .sharebutton {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #333;
  }
  
  .related-posts {
    margin-top: 10px;
  }
  
  .tag-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .badge {
    text-decoration: none;
    background-color: #ffffff;

    padding: .6rem 1rem;
    margin-right: .5rem;
    margin-bottom: 5px;
    /* border: .01rem solid #333; */
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .badge a{
    color: #000000;
    text-decoration: none;
  }
  
  .my-5 {
    margin-bottom: 5rem;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .col-md-3,
    .col-md-9 {
      width: 100%;
      padding: 0;
    }
  }
  /* Add this CSS to your existing stylesheet */

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 1rem;
}

.blogimage {
  cursor: pointer;
}

.blogimage:hover + .popup {
  display: flex;
}

/* .pagination-container{
  background-color: #0d6efd;
  margin: 1rem;
} */
.pagination .page-button{
  background-color: #6213e2c2;
  color: white;
  border: none;
  margin-top: .1rem;
  margin-right: .2rem;
}

.suggestions{
  padding: .5rem;
  
}
.suggestions a{
  color: #000000;
}





.topic-list{
  list-style:upper-roman;

  margin-bottom: .4rem;
  padding: .5rem .3rem;
}

.topic-list a{
  text-decoration: none;
}

.topic-list a:hover{
  background-color: rgba(137, 43, 226, 0.111);
  text-decoration: underline;
}








/* Codebook.css */

.codeblock {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: .5rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.codeblock:hover {
  transform: translateY(-5px);
  box-shadow: rgba(17, 17, 26, 0.2) 0px 4px 16px, rgba(17, 17, 26, 0.2) 0px 8px 24px, rgba(17, 17, 26, 0.2) 0px 16px 56px;
}

.codeblock img {
  border-radius: .5rem 0 0 .5rem;
  transition: transform 0.3s ease;
}

.codeblock .card-body {
  padding: 1rem;
}

.codeblock h5 {
  text-decoration: none;
}

@media (max-width: 768px) {
  .codeblock {
    display: flex;
    flex-direction: column;
  }

  .codeblock img {
    border-radius: .5rem .5rem 0 0;
  }

  .codeblock .col-md-4 {
    flex: 1 0 auto;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .codeblock .col-md-8 {
    flex: 1 0 auto;
    max-width: 100%;
  }
}

.sidebar {
  margin-top: 2rem;
}

.ad-placeholder {
  margin-top: 2rem;
  background-color: #f0f0f0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .codeblock {
    flex-direction: column;
  }

  .ad-placeholder {
    height: 150px;
  }
}
